<template>
  <v-card class="mx-auto overflow-hidden" :class="(isNew && sellerID == null) ? 'transparent' : ''" height="100%">
    <v-app-bar
      color="secondary"
      app
      :extension-height="showDetails ? 75 : 0">
      
      <v-btn :to="{ name: 'purchasing-orders' }" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-toolbar-title v-if="isNew">New Order</v-toolbar-title>
      <v-toolbar-title v-else-if="purchaseOrder">{{ purchaseOrder.seller.companyName }}</v-toolbar-title>
      <v-spacer />
      <v-btn v-if="!isNew" icon @click="showDetails = !showDetails"><v-icon>mdi-information-outline</v-icon></v-btn>

      <template v-slot:extension>
        <v-container v-if="showDetails && purchaseOrder != null">
            <v-list-item dense>                  
                <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                <v-list-item-action-text>{{ purchaseOrder.customerOrderNumber }}</v-list-item-action-text>                  
            </v-list-item>
            <v-list-item dense>                  
                <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                <v-list-item-action-text>{{ purchaseOrder.purchaseOrderNumber }}</v-list-item-action-text>                  
            </v-list-item>
        </v-container>       
      </template>
    </v-app-bar>

    <v-navigation-drawer v-if="isEditing" v-model="drawer" absolute temporary style="height: 100%;">
        <v-app-bar color="secondary lighten-1" dense>
            <!-- <v-toolbar-title>{{ drawerSlot ? 'Order Slots' : 'Products' }}</v-toolbar-title> -->
            <v-toolbar-title>Products</v-toolbar-title>
            <v-spacer />
        <!-- <v-btn v-if="showDrawerSlot" icon @click="drawerSlot = !drawerSlot">
          <v-icon>mdi-menu</v-icon>
        </v-btn> -->
      </v-app-bar>
        <v-list dense>
            <template v-for="orderItem in orderItems">
                <Order-Line-Item 
                    :canEdit="isEditing"
                    :item="orderItem"
                    :key="orderItem.productID"
                    @change="update" />
                <v-divider :key="'d' + orderItem.productID" />
            </template>
        </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer v-else-if="purchaseOrder" v-model="progressDrawer" absolute temporary style="height: 100%;">
        <v-app-bar color="secondary lighten-1" dense>
            <v-toolbar-title>Progress</v-toolbar-title>
            <v-spacer />        
      </v-app-bar>
        <v-timeline align-top dense v-if="purchaseOrder != null">
            <v-timeline-item small dense>
                <template v-slot:icon>
                    <v-icon v-if="purchaseOrder.confirmedOn != null" class="white--text">mdi-check-circle</v-icon>
                    <v-icon v-else class="white--text">mdi-close-circle</v-icon>
                </template>
                <v-list-item>                
                <v-list-item-content v-if="purchaseOrder.confirmedOn != null">
                    <v-list-item-subtitle>Approved On</v-list-item-subtitle>
                    <label class="caption">{{ purchaseOrder.confirmedOn | toDayMonth }}</label>                                            
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-list-item-subtitle class="caption">Awaiting Confirmation</v-list-item-subtitle>                                            
                </v-list-item-content>
                </v-list-item>
            </v-timeline-item>
            <v-timeline-item v-if="purchaseOrder.confirmedOn != null" small dense>
                <template v-slot:icon>
                    <v-icon v-if="stockOrder != null && stockOrder.consignmentID != null" class="white--text">mdi-check-circle</v-icon>
                    <v-icon v-else class="white--text">mdi-close-circle</v-icon>
                </template>
                <v-list-item>                
                <v-list-item-content>
                    <v-list-item-subtitle v-if="stockOrder != null && stockOrder.consignmentID != null">Consigned</v-list-item-subtitle>                    
                    <v-list-item-subtitle v-else class="caption">Awaiting Consignment</v-list-item-subtitle>                                            
                </v-list-item-content>                
                </v-list-item>
            </v-timeline-item>
            <v-timeline-item v-if="consignment != null" small dense>
                <template v-slot:icon>
                    <v-icon v-if="consignment.packedOn != null" class="white--text">mdi-check-circle</v-icon>
                    <v-icon v-else class="white--text">mdi-close-circle</v-icon>
                </template>
                <v-list-item>                
                <v-list-item-content>
                    <v-list-item-subtitle v-if="consignment.packedOn != null">Packed On</v-list-item-subtitle>                    
                    <v-list-item-subtitle v-else class="caption">Packing</v-list-item-subtitle>                                   
                    <label v-if="consignment.packedOn != null" class="caption">{{ consignment.packedOn | toDayMonth }}</label>
                </v-list-item-content>                
                </v-list-item>
            </v-timeline-item>
            <v-timeline-item v-if="consignment != null" small dense>
                <template v-slot:icon>
                    <v-icon v-if="consignment.readyOn != null" class="white--text">mdi-check-circle</v-icon>
                    <v-icon v-else class="white--text">mdi-close-circle</v-icon>
                </template>
                <v-list-item>         
                <v-list-item-content v-if="consignment.readyOn != null">
                    <v-list-item-subtitle>Readied On</v-list-item-subtitle>
                    <label class="caption">{{ consignment.readyOn | toDayMonth }}</label>                                            
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-list-item-subtitle class="caption">Readying</v-list-item-subtitle>                                            
                </v-list-item-content>                       
                </v-list-item>
            </v-timeline-item>                        
            <v-timeline-item v-if="consignment != null && consignment.isBuyerCourier && !consignment.isOrdered && consignment.readyOn != null" small dense>
                <template v-slot:icon>
                    <v-icon class="white--text">mdi-multiple-blank-circle-outline</v-icon>                    
                </template>
                <v-list-item>         
                <v-list-item-content>
                    <v-btn class="primary--text ma-0 pa-0" small dense>
                        Organize Courier
                    </v-btn>                    
                </v-list-item-content>                
                </v-list-item>
            </v-timeline-item>                        
            <v-timeline-item v-if="consignment != null && consignment.isOrdered" small dense>
                <template v-slot:icon>
                    <v-icon class="white--text">mdi-check-circle</v-icon>
                </template>
                <v-list-item>         
                <v-list-item-content>
                    <v-list-item-subtitle class="caption">Courier Ordered</v-list-item-subtitle>                                            
                </v-list-item-content>                
                </v-list-item>
            </v-timeline-item>                        
        </v-timeline>
    </v-navigation-drawer>

    <v-alert v-model="showError" dismissible type="error">{{ error }}</v-alert>

    <v-card-text v-if="isNew && sellerID == null">        
            <v-list class="transparent">                
                <v-card v-for="supplier in suppliers" :key="supplier.id" @click="selectSupplier(supplier)">
                <v-list-item :key="supplier.id">                                        
                    <v-list-item-avatar>
                        <v-img :src="companyLogoURL(supplier.sellerID)" class="my-auto">
                            <template v-slot:placeholder><v-icon class="primary--text" size="40">mdi-factory</v-icon></template>
                        </v-img>
                    </v-list-item-avatar>
                    <v-list-item-title>{{ supplier.seller.companyName }}</v-list-item-title>                    
                </v-list-item>
                </v-card>                
            </v-list>        
    </v-card-text>
    <v-card-text v-else-if="purchaseOrder">
        <v-form ref="form">
            <BT-Select
                v-if="isEditing && currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'"
                :items="dueDateOptions"
                v-model="purchaseOrder.dueDate"
                prependIcon="mdi-calendar"
                label="Delivery Date (Slotted)">
                <template slot="item" slot-scope="{ item }">
                    {{ item | toDayShortDate }}
                </template>
                <template slot="selection" slot-scope="{ item }">
                    {{ item | toDayShortDate }}
                </template>
            </BT-Select>
            <BT-Date-Picker            
                v-else-if="isEditing && currentPolicy != null && (currentPolicy.orderArrangement === 'Open' || currentPolicy.orderArrangement == null)"
                v-model="purchaseOrder.dueDate"
                color="primary"
                :disabled="!isEditing"
                :dateRules="dateRules"
                :dateFrom="dateFrom"
                :label="(currentPolicy == null || currentPolicy.orderingArrangement == 'Open' || currentPolicy.orderingArrangement == null) ? 'Delivery Date' : 'Order Slot'" />            

            <BT-Location
                v-if="!isEditing"
                :location="purchaseOrder.location"
                label="Destination"
                :includeName="true" />
            <BT-Select
                v-else
                navigation="locations"
                item-text="locationName"
                item-value="id"
                label="Destination"
                prepend-icon="mdi-map-marker"
                v-model="purchaseOrder.locationID" />

            <v-checkbox
                v-model="purchaseOrder.isBuyerCourier"
                label="I Will Organize Pickup"
                :disabled="!isEditing" />
        
            <v-row dense>
                <v-btn v-if="isEditing" color="primary" icon @click="drawer = true"><v-icon>mdi-table-column-plus-after</v-icon></v-btn>
                <v-divider class="my-auto" />
                <span class="mx-4 my-auto">Line Items</span>
                <v-divider class="my-auto" />
            </v-row>

            <v-list v-if="!isEditing">
                <template v-for="(orderItem, index) in purchaseOrder.orderItems">                                    
                    <v-list-item    
                        dense            
                        :key="index"
                        two-line>
                        <template>
                            <v-list-item-content>
                                <v-list-item-title>{{ orderItem.description }}</v-list-item-title>
                                <v-list-item-subtitle v-if="orderItem.productID" class="primary--text"><strong>QTY: {{ orderItem.quantity | toDisplayNumber }}</strong></v-list-item-subtitle>                        
                            </v-list-item-content>                    
                            <v-list-item-action>
                                {{ (orderItem.unitPrice * orderItem.quantity) | toCurrency }}
                            </v-list-item-action>
                        </template>                                
                    </v-list-item>     
                    <v-divider :key="'d' + index" />       
                </template>
            </v-list>
            <v-list v-if="isEditing">
                <template v-for="(orderItem, index) in filteredOrderItems">                
                    <Order-Line-Item 
                        :canEdit="isEditing"
                        :item="orderItem"
                        :key="index"
                        @change="update" />                    
                    <v-divider :key="'d' + index" />       
                </template>
            </v-list>
            <v-spacer v-if="isEditing" />
            <v-list v-if="isEditing">
                <template v-for="(orderItem, index) in autoOrderItems">                
                    <Order-Line-Item                         
                        :item="orderItem"
                        :key="index"
                        @change="update" />                    
                    <v-divider :key="'d' + index" />       
                </template>
            </v-list>
        </v-form>
    </v-card-text>
   
   <v-app-bar
    v-if="purchaseOrder != null"
    dense    
    bottom     
    app
    color="secondary">
    <v-btn v-if="!isEditing && purchaseOrder.isConfirmed == null" text @click="edit">
        <v-icon left>mdi-pencil</v-icon>Edit
    </v-btn>
    <v-btn v-else-if="!isEditing && purchaseOrder.isConfirmed == true" text @click="showProgress">
        <v-icon left>mdi-progress-check</v-icon>Progress
    </v-btn>
    <v-btn v-if="isChanged" text @click="save">
        <v-icon left>mdi-content-save</v-icon>Save
    </v-btn>
    <v-spacer />
    <v-btn v-if="!isEditing && purchaseOrder.isConfirmed == null" text @click="deleteOrder" color="red">
        <v-icon left color="red">mdi-delete</v-icon>Delete
    </v-btn>    
    <v-spacer />
    <v-toolbar-title>{{ purchaseOrder.totalPrice | toCurrency }}</v-toolbar-title>
   </v-app-bar>

    <v-overlay :value="loading" class="text-center">
        <v-progress-circular indeterminate size="64" />
        <p>Loading</p>
    </v-overlay>  
  </v-card>

</template>

<script>
import { getUnitPrice } from '~tools/helpers.js';
import moment from 'moment-timezone';
import { firstBy } from 'thenby';

export default {
  name: "Ordering-Item",
    components: {            
        BTLocation: () => import('~components/BT-Location.vue'),        
        OrderLineItem: () => import('~home/Purchase-Orders/Ordering-Line-Item.vue')
    },  
  data: function () {
    return {
        autoOrderItems: [],
        consignment: null,
        currentPolicy: null,        
        dateFrom: null,  
        dateRules: null,
        drawer: false,
        drawerSlot: false,
        dueDateOptions: [],
        error: null,
        group: null,        
        id: null,
        isEditing: false,
        isChanged: false,
        loading: false,     
        orderItems: [],   
        progressDrawer: false,
        purchaseOrder: null,  
        sellerID: null,      
        showError: false,
        showDetails: false,      
        //showingProgress: false,
        stockOrder: null,        
        suppliers: [],              
    };
  },  
  created() {
      this.id = this.$route.params['id'];      
  },  
  mounted() {    
    if (!this.isNew) {
        this.pullOrder();
    }  
    else {
        this.pullSuppliers();
    }  
  },
  computed: {
      isNew() {
        return this.id == 'new';
      },
      filteredOrderItems() {
          return this.orderItems.filter(y => y.quantity > 0);
      },
      showDrawerSlot() {
          return this.currentPolicy != null && this.currentPolicy.orderingArrangement == 'Slotted';
      }
  },
  methods: {
    formError(err) {
      this.error = this.extractErrorDescription(err);
      this.showError = true;
    },
    showProgress() {
        this.progressDrawer = true;
        var self = this;

        if (this.purchaseOrder.isConfirmed === true && this.purchaseOrder.isDispatched) {
            //then look for stock purchase order
            self.loading = false;

            this.$BlitzIt.store.get('stock-purchase-orders', this.purchaseOrder.id, { properties: 'ID,ConsignmentID' }, true)
                .then(res => {
                    self.stockOrder = res;
                    if (res.consignmentID != null) {                        
                        self.$BlitzIt.store.get('supplier-consignments', res.consignmentID, { properties: 'ID,ReadyOn,PackedOn,IsOrdered,IsBuyerCourier' }, true)
                            .then(res => {
                                self.consignment = res;                                
                            })  
                            //.catch(err => { self.formError(err); })
                            .finally(() => { 
                                self.loading = false;                                 
                            })                          
                    }
                    else{
                        self.loading = false;
                    }
                })
                .catch(() => { 
                    //self.formError(err); 
                    self.loading = false;
                })                                
        }        
    },
    save() {
        if (this.purchaseOrder != null && this.isChanged && this.$refs.form.validate()) {
            var self = this;
            self.loading = true;            
            if (this.isNew) {                
                self.purchaseOrder.orderItems = self.filteredOrderItems.map(x => ({ productID: x.productID, quantity: x.quantity }));
                this.$BlitzIt.store.post('purchase-orders', self.purchaseOrder)
                    .then(() => {                        
                        self.$router.push({ name: 'purchasing-orders' });
                    })
                    .catch(err => { self.formError(err); })
                    .finally(() => { self.loading = false; })
            }
            else {
                this.$BlitzIt.store.patch('purchase-orders', self.purchaseOrder)
                    .then(() => {                        
                        self.$router.push({ name: 'purchasing-orders' });
                    })
                    .catch(err => { self.formError(err); })
                    .finally(() => { self.loading = false; })
            }            
        }
    },
    selectSupplier(supplier) {        
        this.purchaseOrder = {
            supplyAgreementID: supplier,
            locationID: null,
            dueDate: null,
            isBuyerCourier: null,
            orderItems: []
        }
        this.sellerID = supplier.id;
        this.edit();
    },
    pullOrder() {          
        if (this.purchaseOrder == null) {
            var self = this;
            self.loading = true;            
            this.$BlitzIt.store.get('purchase-orders', self.id)
                .then(res => {                     
                    res.orderItems.orderBy('sortNumber');
                    self.purchaseOrder = res;
                    self.sellerID = res.supplyAgreementID;
                })
                .catch(err => { 
                    console.log(err);
                    self.formError(err); })
                .finally(() => { self.loading = false; })
        }
    },
    pullSuppliers() {
        if (this.suppliers.length == 0) {
            var self = this;
            self.loading = true;
            this.$BlitzIt.store.getAll('suppliers', null)
                .then(res => {                    
                    self.suppliers = res;                    
                    self.suppliers.orderBy('seller.companyName');
                })
                .catch(err => { self.formError(err); })
                .finally(() => { self.loading = false; })
        }
    },    
    maybeOpenSlotDrawer() {                
        this.drawer = true;
        this.drawerSlot = true;        
    },
    edit() {
        if (this.currentPolicy == null && this.sellerID != null) {
            var self = this;
            self.loading = true;
            self.$BlitzIt.api.get('suppliers', '/get/Policy/' + self.sellerID, null, null)
                .then(res => {
                    self.currentPolicy = res.data.data;

                    for (var a = 0; a < res.data.data.orderItems.length; a++) {
                        var oItem = res.data.data.orderItems[a];
                                                
                        oItem.description = oItem.product.productName;
                        oItem.quantity = 0;
                        oItem.unitPrice = getUnitPrice(oItem.unitPriceIncrements, 0);
                        oItem.totalPrice = 0;

                        if (self.purchaseOrder.orderItems) {
                            var eItem = self.purchaseOrder.orderItems.find(x => x.productID == oItem.productID);
                            if (eItem) {
                                oItem.id = eItem.id;
                                oItem.rowVersion = eItem.rowVersion;
                                oItem.quantity = eItem.quantity;
                                oItem.unitPrice = getUnitPrice(oItem.unitPriceIncrements, oItem.quantity);                                
                                oItem.tags = eItem.tags;
                                oItem.sortNumber = eItem.sortNumber;
                            }
                        }                        

                        oItem.totalPrice = oItem.unitPrice * oItem.quantity;

                        self.orderItems.push({
                            data: oItem,
                            id: oItem.id ? oItem.id : null,
                            rowVersion: oItem.rowVersion ? oItem.rowVersion : null,
                            description: oItem.description,
                            unitPrice: oItem.unitPrice,
                            totalPrice: oItem.totalPrice,
                            quantity: oItem.quantity,
                            productID: oItem.productID,                            
                            tags: oItem.tags ? oItem.tags : oItem.product.tags,
                            isClosedOrdering: oItem.rules && oItem.rules.some(x => x.rule === 'QuantityInIncrements')
                        });
                    }
                       
                    if (self.isNew) {
                        self.purchaseOrder.locationID = self.currentPolicy.defaultLocationID;                        
                        self.purchaseOrder.supplyAgreementID = self.sellerID;                                                
                        self.purchaseOrder.isBuyerCourier = self.currentPolicy.isRequirePickup ? true : self.currentPolicy.isPreferPickup;
                    }
                    else {
                        //sort order items
                        self.purchaseOrder.orderItems.orderBy('sortNumber');
                    }

                    if (self.currentPolicy.orderingArrangement === 'Slotted') {
                        for (var i = 0; i < self.currentPolicy.orderSlots.length; i++) {
                            var slot = self.currentPolicy.orderSlots[i];
                            if (!slot.purchaseOrderID) {
                                self.dueDateOptions.push(slot.deliverBy);
                            }
                        }
                        
                        this.dueDateOptions.sort();
                    }
                    else if (self.currentPolicy.requireLeadTime) {                        
                        self.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, this.currentPolicy.orderLeadHours);

                        if (!self.currentPolicy.isAvailableWeekends) {
                            self.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }
                    else {
                        //self.dateFrom = self.$BlitzIt.auth.createTZ('YYYY-MM-DD'); //moment().format('YYYY-MM-DD');
                        self.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, this.currentPolicy.orderLeadHours);

                        if (!self.currentPolicy.isAvailableWeekends) {
                            self.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }    

                    self.update();
                    self.isEditing = true;
                })
                .catch(err => { self.formError(err); })
                .finally(() => { self.loading = false; })
        }        
    },
    update() {
        //recalculate
        this.updateAutoItems();
        this.calculateTotalPrice();
        this.applyGlobalRules();
        this.isChanged = true;
    },
    deleteOrder() {        
        if (!this.isNew && this.purchaseOrder != null && this.purchaseOrder.isConfirmed == null) {
            var self = this;
            self.loading = true;                
            this.$BlitzIt.store.delete('purchase-orders', this.purchaseOrder.id)
                .then(() => {                        
                    self.$router.push({ name: 'purchasing-orders' });
                })
                .catch(err => { self.formError(err); })
                .finally(() => { self.loading = false; })
        }
    },
    applyGlobalRules() {

    },
    calculateTotalPrice() {
            var prodTotal = this.orderItems.sum(x => x.totalPrice);
            var autoTotal = this.autoOrderItems.sum(x => x.totalPrice);
            this.purchaseOrder.totalPrice = prodTotal + autoTotal;
    },
    updateAutoItems() {            
            this.autoOrderItems = [];
            var oItems = [];
            oItems.push.apply(oItems, this.orderItems);

            if (!this.currentPolicy.automaticLineItems) {
                return;
            }

            this.currentPolicy.automaticLineItems.sort(firstBy(x => x.calcOrder).thenBy(x => x.quantity));
            var optionIDsUsed = [];

            for (var i = 0; i < this.currentPolicy.automaticLineItems.length; i++) {
                var autoItem = this.currentPolicy.automaticLineItems[i];
                if (autoItem.optionGroupID == null || !optionIDsUsed.some(z => z == autoItem.optionGroupID)) {
                    if (autoItem.optionGroupID != null) {
                        optionIDsUsed.push(autoItem.optionGroupID);
                    }

                    var relevantValue = null;
                    var newItem = {
                        data: null,
                        quantity: 1,
                        unitPrice: 0,
                        totalPrice: 0,
                        tags: autoItem.tags,
                        description: autoItem.lineItemName,
                        lineItemCode: autoItem.id,
                        isClosedOrdering: true
                    }

                    if (autoItem.rule) {
                        var rule = autoItem.rule;

                        var relevantProductItems = oItems.filter(x => x.data && x.data.productID);
                        var relevantOrderItems = oItems.filter(x => x.tags && x.tags.csvContains(autoItem.linesWithTags));
                        
                        var totalValue = relevantOrderItems.sum(x => x.totalPrice);
                        var productValue = relevantProductItems.sum(x => x.totalPrice);
                        var productQty = relevantProductItems.sum(x => x.quantity);
                        
                        if (rule === 'ProductValueGreaterThan' && productValue > autoItem.quantity ||
                            rule === 'ProductValueLessThan' && productValue < autoItem.quantity ||
                            rule === 'ProductValueEqualTo' && productValue === autoItem.quantity ||
                            rule === 'TotalQuantityGreaterThan' && productQty > autoItem.quantity ||
                            rule === 'TotalQuantityLessThan' && productQty < autoItem.quantity ||
                            rule === 'TotalQuantityEqualTo' && productQty === autoItem.quantity) {
                            
                            relevantValue = productValue;
                        }
                        else if (rule === 'TotalValueGreaterThan' && totalValue > autoItem.quantity ||
                            rule === 'TotalValueLessThan' && totalValue < autoItem.quantity ||
                            rule === 'TotalValueEqualTo' && totalValue === autoItem.quantity) {
                            
                            relevantValue = totalValue;
                        }
                        
                        if (relevantValue) {
                            var unitVal = autoItem.lineValue;
                            var unitType = autoItem.lineValueType;

                            if (unitType) {
                                if (unitType === 'Percent') {
                                    newItem.unitPrice = unitVal * relevantValue;
                                }
                                else if (unitType === 'FixedValue') {
                                    newItem.unitPrice = unitVal;
                                }
                                else {
                                    newItem.unitPrice = unitVal;
                                }
                            }
                            else {
                                newItem.unitPrice = unitVal;
                            }

                            newItem.totalPrice = newItem.unitPrice * newItem.quantity;
                            this.autoOrderItems.push(newItem);
                            oItems.push(newItem);
                        }
                    }
                }
            }


    }
  }
};
</script>